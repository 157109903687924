/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { navigate, Link } from 'gatsby'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx'
import profileImage from '../../static/uploads/ProfileImage.png'
import navbarsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.jsx'
import Header from 'components/Header/Header.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import useAuth from '../firebase/useAuth'
import firebase from '../firebase'
import styled from 'styled-components'
import Logo from '../images/flex-logo-small.png'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Img = styled.img`
  height: 75px;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    height: 65px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 750px) {
    height: 55px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media (max-width: 500px) {
    height: 45px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

const NavBarWithNotifications = props => {
  const user = useAuth()
  const [Email, setEmail] = React.useState('Guest_User')
  const [role, setRole] = React.useState('')

  React.useEffect(() => {
    handleEmail()
    return function cleanup() {
      handleEmail()
      user
      Email
      role
    }
  }, [user, Email, role])

  const handleEmail = () => {
    if (user) {
      setEmail(user.email)
      if (user.uid) {
        const userRef = firebase.db.collection('users').doc(user.email)
        userRef.get().then(doc => {
          if (doc.exists) {
            setRole(doc.data().role)
          }
        })
      }
    } else {
      setEmail('Guest_User')
    }
  }
  const DropDownValue = user
    ? ['My Profile', 'Sign out']
    : ['Sign in', 'Sign up']

  const onProfileMenuClick = menuItem => {
    if (menuItem === 'Sign up') {
      navigate('/signup')
    }
    if (menuItem === 'Sign out') {
      firebase.logout()

      navigate('/')
    }
    if (menuItem === 'Sign in') {
      navigate('/login')
    } else if (menuItem === 'My Profile') {
      navigate('/editprofile')
    }
  }

  const { classes, ...rest } = props

  return (
    <>
      <Header
        brand={<Img src={Logo} />}
        color="dark"
        links={
          <List className={classes.list + ' ' + classes.mlAuto}>
            <ListItem className={classes.listItem}>
              <Button
                onClick={() => navigate('/')}
                className={classes.navLink}
                color="transparent"
              >
                Home
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                onClick={() => navigate('/faq')}
                className={classes.navLink}
                color="transparent"
              >
                FAQ
              </Button>
            </ListItem>

            <ListItem className={classes.listItem}>
              {role === 'Freelancer' ? (
                <Button
                  onClick={() => navigate('/editprofile')}
                  className={classes.navLink}
                  color="transparent"
                >
                  Apply
                </Button>
              ) : (
                <Button
                  onClick={() => navigate('/apply')}
                  className={classes.navLink}
                  color="transparent"
                >
                  Apply
                </Button>
              )}
            </ListItem>

            <ListItem className={classes.listItem}>
              <Button
                onClick={() => navigate('/hire')}
                className={classes.navLink}
                color="transparent"
              >
                Hire Talent
              </Button>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Button
                onClick={() => navigate('/cases#section-0')}
                className={classes.navLink}
                color="transparent"
              >
                Case Studies
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                onClick={() => navigate('/contact')}
                className={classes.navLink}
                color="transparent"
              >
                Contact Us
              </Button>
            </ListItem>
            {user ? (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  left
                  caret={true}
                  hoverColor="dark"
                  dropdownHeader={Email}
                  buttonText={
                    <img
                      src={user.photoURL ? user.photoURL : profileImage}
                      className={classes.img}
                      alt="profile"
                    />
                  }
                  buttonProps={{
                    className:
                      classes.navLink + ' ' + classes.imageDropdownButton,
                    color: 'transparent',
                  }}
                  dropdownList={DropDownValue}
                  onClick={menuItem => onProfileMenuClick(menuItem)}
                />
              </ListItem>
            ) : (
              <ListItem className={classes.listItem}>
                <Button
                  className={classes.navLink}
                  color="transparent"
                  onClick={() => navigate('/login')}
                >
                  Login
                </Button>
              </ListItem>
            )}
          </List>
        }
        {...rest}
      />
    </>
  )
}

NavBarWithNotifications.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(navbarsStyle)(NavBarWithNotifications)
