import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import withStyles from '@material-ui/core/styles/withStyles'

import landingPageStyle from '../materialkit/assets/jss/material-kit-pro-react/views/landingPageStyle'
import ForestryFooter from './footer'
import favicon from './favicon.jpg'
import FBG from '../../static/uploads/flexBg.png'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              footer
            }
          }
        }
      `}
      render={data => (
        <Wrapper>
          <Helmet
            link={[
              {
                rel: 'shortcut icon',
                type: 'image/png',
                href: `${favicon}`,
              },
            ]}
          >
            {/* <!-- Primary Meta Tags --> */}
            <title>Flexatal</title>
            <meta name="title" content={data.site.siteMetadata.title} />
            <meta
              name="description"
              content="THE WORLD’S BEST FREELANCE TECHNOLOGY TALENT AVAILABLE ON DEMAND."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.flexatal.com/" />
            <meta property="og:title" content="Flexatal" />
            <meta
              property="og:description"
              content="THE WORLD’S BEST FREELANCE TECHNOLOGY TALENT AVAILABLE ON DEMAND."
            />
            <meta property="og:image" content={FBG} />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content="https://twitter.com/flexatal"
            />
            <meta property="twitter:title" content="Flexatal" />
            <meta
              property="twitter:description"
              content="THE WORLD’S BEST FREELANCE TECHNOLOGY TALENT AVAILABLE ON DEMAND."
            />
            <meta property="twitter:image" content={FBG} />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons"
            />
            <link
              href="https://use.fontawesome.com/releases/v5.0.10/css/all.css"
              rel="stylesheet"
            />
            <html lang="en" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />

          <Content>{children}</Content>
          <ForestryFooter />
        </Wrapper>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(landingPageStyle)(Layout)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Content = styled.div`
  flex: 1;
`
