import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import logo from '../images/Icon_Transparent_Background.png'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  background-color: #dedede;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 18%;
  font-size: 15px;
  line-height: 1.2rem;
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    align-items: center;
  }
`

const FooterHeader = styled.h3`
  text-transform: uppercase;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  margin: 0 0px 20px;
  @media (max-width: 768px) {
    margin: auto;
  }

  color: ${props => props.color};
`

const StyledLink = styled(props => <Link {...props} />)`
  text-transform: uppercase;
  margin: 0px 0px 4px 0;
  color: #232323;
  text-emphasis: none;
  font-weight: bold;
  width: 90%;
  :hover {
    color: #ff5a00;
  }
  @media (max-width: 768px) {
    margin: auto;

    text-align: center;
  }
`

const StyledA = styled.a`
  text-transform: uppercase;
  margin: 0px 0px 4px 0;
  color: #232323;
  text-emphasis: none;
  font-weight: bold;
  width: 90%;
  :hover {
    color: #ff5a00;
  }
  @media (max-width: 768px) {
    margin: auto;

    text-align: center;
  }
`

const FooterImage = styled.img`
  height: 210px;

  margin: -15px 20px 0 0;
  @media (max-width: 768px) {
    margin: 20px auto;
    text-align: center;
  }
`
const FooterLine = styled.div`
  height: 5px;
  width: 80%;
  background-color: ${props => props.color};
  margin-bottom: 15px;
  @media (max-width: 768px) {
    margin: 20px;
    width: 40%;
  }
`
const FooterIcons = styled.div`
  display: flex;
  flex-direction: row;
`
const pink = '#ff0066'
const orange = '#ff5a00'
const yellow = '#ff9000'

const ForestryFooter = props => {
  return (
    <Wrapper>
      <FooterImage src={logo}></FooterImage>

      <FooterColumn>
        <FooterLine color={pink} />
        <FooterHeader color={pink}>Links</FooterHeader>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/apply">Apply as a freelancer</StyledLink>
        <StyledLink to="/hire">Hire Talent</StyledLink>
        <StyledLink to="/contact">Contact Us</StyledLink>
        <StyledLink to="/faq">FAQ</StyledLink>
        <StyledA href="https://firebasestorage.googleapis.com/v0/b/flexatal.appspot.com/o/Flexatal%20PRIVACY%20NOTICE.pdf?alt=media&token=95fc24a5-12ea-4c41-80c8-b614bcd035f6">
          Privacy Policy
        </StyledA>
      </FooterColumn>
      <FooterColumn>
        <FooterLine color={orange} />
      </FooterColumn>
      <FooterColumn>
        <FooterLine color={yellow} />
        <FooterHeader color={yellow}>Social</FooterHeader>
        <FooterIcons>
          <a href="https://www.linkedin.com/company/flexatal">
            <FaLinkedinIn style={{ fontSize: 35, padding: 5, color: pink }} />
          </a>

          <a href="https://www.facebook.com/Flexatal/">
            <FaFacebookF style={{ fontSize: 35, padding: 5, color: orange }} />
          </a>
          <a href="https://twitter.com/flexatal">
            <FaTwitter style={{ fontSize: 35, padding: 5, color: yellow }} />
          </a>
        </FooterIcons>
      </FooterColumn>
    </Wrapper>
  )
}

ForestryFooter.propTypes = {
  classes: PropTypes.object,
}

export default ForestryFooter
